<template>
    <div class="phi-person-set-condition-person">
        <div class="main person"
            @click="toggle(value._person)"
            :class="{selected: isSelected(value._person)}"
        >
            <div class="phi-media">
                <div class="phi-media-figure phi-avatar">
                    <img :src="value._person.avatar" :alt="value._person.firstName" />
                </div>
                <div class="phi-media-body">
                    <h3>{{ value._person.firstName }} {{ value._person.lastName }}</h3>
                </div>
            </div>
            <mu-icon :value="isSelected(value._person) ? 'check_box' : 'check_box_outline_blank'"></mu-icon>
        </div>

        <div class="relatives">
            <div class="person"
                v-for="person in relatives"
                :key="person.id"
                @click="toggle(person)"
                :class="{selected: isSelected(person)}"
            >
                <div class="phi-media">
                    <div class="phi-media-figure phi-avatar">
                        <img :src="person.avatar" :alt="person.firstName" />
                    </div>
                    <div class="phi-media-body">
                        <h3>{{ person.firstName }} {{ person.lastName }}</h3>
                        <span class="relations">
                            <span v-for="relation in person.relations" :key="relation.name">{{ $t(relation.name) }}</span>
                        </span>
                    </div>
                </div>
                <mu-icon :value="isSelected(person) ? 'check_box' : 'check_box_outline_blank'"></mu-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-person",
    props: ["api", "value"],
    data() {
        return {
            relatives: []
        }
    },

    methods: {
        loadRelatives() {
            this.api
                .get(`/people/${this.value._person.id}/relatives`)
                .then(response => this.relatives = response);
        },

        isSelected(person) {
            return this.value.id.indexOf(person.id) >= 0;
        },

        select(person) {
            if (!this.isSelected(person)) {
                this.value.id.push(person.id);
                this.$emit('input', this.value);
            }
        },

        deselect(person) {
            // Make sure always 1 person is selected
            if (this.value.id.length == 1) {
                return;
            }

            this.value.id.splice(this.value.id.indexOf(person.id), 1);
            this.$emit('input', this.value);
        },

        toggle(person) {
            this.isSelected(person) ? this.deselect(person) : this.select(person);
        }
    },

    mounted() {
        this.loadRelatives();
    }
}
</script>

<style lang="scss">
.phi-person-set-condition-person {
    .main.person {
        border-bottom: 1px solid #ddd;
        margin-bottom: 12px;
    }

    .person {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 24px;

        .mu-icon {
            color: #666;
        }

        .phi-media {
            align-items: center;
            flex: 1;
            padding: 12px 0;
        }

        .relations {
            font-size: .8em;
            color: #777;

            span::after {
                content: ', ';
            }

            span:last-child::after {
                content: ''
            }
        }

        &:hover {
            background-color: rgba(0,0,0,.04);
        }

        &.selected {
            background-color: #edf4fd;
        }
    }
}
</style>
