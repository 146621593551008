<template>
    <select v-model="isDebtor" @change="$emit('input', isDebtor)">
        <option :value="true">Deudor</option>
        <option :value="false">Al día</option>
    </select>
</template>

<script>
export default {
    name: "phi-person-set-condition-debtor",
    props: {
        value: {}
    },
    data() {
        return {
            isDebtor: this.value
        }
    }
}
</script>