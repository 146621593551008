<template>
    <div class="phi-person-set-condition-or">

        <div class="chips">
            <div
                v-for="(condition, index) in value"
                :key="index"
                class="chip phi-media"
                :class="{active: activeCondition == condition}"
                @click="editCondition(condition, index)"
            >
                <p class="phi-media-figure">{{ condition.count }}</p>
                <p class="phi-media-body">{{ condition.title }}</p>
                <mu-icon class="phi-media-right" value="cancel" :size="20" @click.stop="removeConditionAt(index)" />
            </div>
        </div>

        <mu-dialog
            scrollable
            :open="!!activeCondition"
            :title="activeCondition ? activeCondition.count + ' ' + activeCondition.title : ''"
            @close="activeConditionCancel"
            dialog-class="activeDialog"
            body-class="activeCondition"
        >
            <phi-person-set-condition
                v-if="activeCondition"
                v-model="activeCondition"
                :api="api"
                @input="updateCount(activeCondition)"
            ></phi-person-set-condition>

            <mu-flat-button primary slot="actions" @click="activeConditionOk" :label="$t('OK')"></mu-flat-button>
            <mu-flat-button slot="actions" @click="activeConditionCancel" :label="$t('action.cancel')"></mu-flat-button>
        </mu-dialog>


        <div class="search">

            <div class="bar">
                <mu-icon class="icon" value="search" color="#bbb" :size="20" />
                <input type="text" v-model="searchQuery" @input="search" />
                <mu-icon-menu
                    icon="group_add"
                    :anchorOrigin="{vertical: 'top',horizontal: 'right'}"
                    :targetOrigin="{vertical: 'top',horizontal: 'right'}"
                    @change="addCondition"
                >
                    <template v-for="condition in presetConditions">
                        <mu-menu-item :value="condition" :title="$t(condition.title)" />
                        <mu-divider v-if="condition.separator" />
                    </template>
                </mu-icon-menu>
            </div>

            <div class="results phi-card" v-show="searchResultsIsOpen">
                <section class="roles" v-show="searchResults.roles.length">
                    <label>Roles</label>
                    <ul>
                        <li v-for="role in searchResults.roles" class="phi-media" @click="selectRole(role)">
                            <p class="phi-media-body">{{ role.name }}</p>
                        </li>
                    </ul>
                </section>

                <section class="employeeGroups" v-show="searchResults.employeeGroups.length">
                    <label>Grupos (colaborador)</label>
                    <ul>
                        <li v-for="employeeGroup in searchResults.employeeGroups" class="phi-media" @click="selectEmployeeGroup(employeeGroup)">
                            <p class="phi-media-body">{{ employeeGroup.name }}</p>
                        </li>
                    </ul>
                </section>

                <section class="people" v-show="searchResults.people.length">
                    <label>{{ $t('noun.People') }}</label>
                    <ul>
                        <li v-for="person in searchResults.people" class="phi-media" @click="selectPerson(person)">
                            <div class="phi-media-figure phi-avatar">
                                <img :src="person.avatar" :alt="person.firstName" />
                            </div>
                            <p class="phi-media-body">
                                {{ person.lastName }} {{ person.lastName2 }}, {{ person.firstName }} 
                                <br><small>({{ $t(person.roleName) }})</small>
                            </p>
                            <mu-icon class="phi-media-right" value="group_add" @click.stop="selectPerson(person, true)"></mu-icon>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-or",

    /* https://vuejs.org/v2/guide/components.html#Circular-References-Between-Components */
    // beforeCreate: function () {
    //     this.$options.components.PhiPersonSetCondition = require('../Condition.vue');
    // },
    components: {
        PhiPersonSetCondition: () => import('../Condition.vue')
    },


	props: {
        value: {
            type: Array,
            required: true
        },

        options: {
            type: Object,
            required: true
        },

        api: {
            type: Object
        }
    },

    data() {
        return {
            activeCondition: null,
            activeConditionIndex: null,

            searchQuery: '',
            searchTimeout: null,
            searchResults: {
                people: [],
                roles: [],
                employeeGroups: []
            },

            presetConditions: [
                {
                    title: "noun.People",
                    type: "and",
                    model: [],
                    separator: true
                },

                {
                    title: "noun.Students",
                    type: "and",
                    model: [
                        {
                            type: "type",
                            model: [1]
                        },

                        {
                            type: "enrollment",
                            model: {
                                status: ["2"],
                                section: []
                            }
                        }
                    ]
                },
                {
                    title: "noun.Employees",
                    type: "and",
                    model: [
                        {
                            type: "type",
                            model: [2]
                        }
                    ]
                },
                {
                    title: "noun.Relatives",
                    type: "and",
                    model: [
                        {
                            type: "type",
                            model: [3]
                        }
                    ]
                }
            ]
        }
    },

    computed: {
        searchResultsIsOpen() {
            return this.searchQuery.trim().length && (this.searchResults.people.length || this.searchResults.roles.length || this.searchResults.employeeGroups.length);
        }
    },
    mounted() {
        var self = this;
        this.value.forEach(function(values, index) {
            if(values.type==='v3EmployeeGroups'){
                self.updateCount(values);
            }
        });
    },
    methods: {
        editCondition(condition, index) {
            this.activeCondition = JSON.parse(JSON.stringify(condition));
            this.activeConditionIndex = index;
        },

        activeConditionOk() {
            if (this.activeConditionIndex !== null) {
                this.value[this.activeConditionIndex] = JSON.parse(JSON.stringify(this.activeCondition));
            } else {
                this.value.push(this.activeCondition);
            }

            this.activeCondition = null;
            this.activeConditionIndex = null;
            this.$emit("input", this.value);
        },

        activeConditionCancel() {
            this.activeCondition = null;
            this.activeConditionIndex = null;
        },

        updateCount(condition) {
            return this.api
                .post("/sets/people/count", condition)
                .then(count => {
                    this.$set(condition, 'count', count);
                    return condition;
                });
        },

        addCondition(condition, autoExpand = true) {
            let incomingCondition   = JSON.parse(JSON.stringify(condition)); // deep copy of object
            incomingCondition.title = this.$t(incomingCondition.title);
            this.updateCount(incomingCondition);

            if (autoExpand) {
                this.activeCondition = incomingCondition;
            } else {
                this.value.push(incomingCondition);
                this.$emit("input", this.value);
            }
        },

        removeConditionAt(index) {
            if (this.value[index] == this.activeCondition) {
                this.activeCondition = null;
            }
            this.value.splice(index, 1);
            this.$emit("input", this.value);
        },

        search() {
            let query = this.searchQuery.trim();

            if (query.length < 3) {
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.api
                    .get('/people', {q: query, order: 'lastName'})
                    .then(results => this.$set(this.searchResults, 'people', results));

                this.api
                    .get('/v3/people/groups', {q: query})
                    .then(results => this.$set(this.searchResults, 'roles', results));

                this.api
                    .get('/v3/person/employee/group', {q: query})
                    .then(results => this.$set(this.searchResults, 'employeeGroups', results));

            }, 400);
        },

        selectPerson(person, expand = false) {
            this.addCondition({
                type: "attributes",
                model: {
                    id: [person.id],
                    _person: person
                },
                title: person.firstName + ' ' + person.lastName,
                _component: "phi-person-set-condition-person"
            }, expand);

            this.clearSearch();
        },

        selectRole(role) {
            this.addCondition({
                type: "v3Role",
                model: [role.id],
                title: role.name
            }, false);

            this.clearSearch();
        },

        selectEmployeeGroup(employeeGroup) {
            this.addCondition({
                type: "v3EmployeeGroups",
                model: {
                    group:[employeeGroup.id]
                },
                title: employeeGroup.name
            }, false);

            this.clearSearch();
        },

        clearSearch() {
            this.searchQuery = '';
        }
    }
}
</script>

<style lang="scss">
.phi-person-set-condition-or {

	.chips {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.chip {
			align-items: center;

			margin: 6px;
			padding: 12px 8px;

			border: 1px solid #ddd;
			border-radius: 6px;
			background-color: #f2f2f2;

            cursor: pointer;

			.phi-media-figure {
				margin: 0;
				width: auto;

				font-size: 15px;
				font-weight: bold;
				color: #666;

				padding-left: 6px;
			}

			.phi-media-body {
				overflow: hidden;
				max-width: 160px;
				white-space: nowrap;
				text-overflow: ellipsis;

				padding: 0 6px;
                margin: 0;
			}

			.phi-media-right {
                color: #bbb;
                margin-left: 12px;

                &:hover {
				    color: #B71C1C;
                }
			}

            &.active {
                background-color: #fff;
            }
		}
	}

	.search {
        position: relative;

        .bar {
            display: flex;
            align-items: center;

            & > i {
                margin: 0 8px;
                cursor: default;
            }

            input {
                flex: 1;
                border: 0;
                border-bottom: 1px solid #ccc;
                background: transparent;

                height: 36px;
                line-height: 36px;
                font-size: 1em;
            }

            .mu-icon-menu .mu-icon {
                font-size: 28px;
                color: #444;
            }
        }

        .results {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 99;

            max-height: 400px;
            overflow-y: auto;

            margin: 0 16px;

            section {
                label {
                    display: block;
                    font-size: 12px;
                    color: #777;
                    padding: 8px 12px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    .phi-media {
                        align-items: center;
                    }

                    li:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        cursor: pointer;
                    }
                }
            }

            .people {
                .mu-icon.phi-media-right {
                    cursor: pointer;
                    padding: 8px 12px;
                }

                .mu-icon.phi-media-right:hover {
                    color: dodgerblue;
                }
            }
        }

	}

}

// Defined outside since mu-dialog appends to body
.activeCondition {
    max-height: 500px;
    padding: 0;
    color: inherit;
}

@media only screen and (max-width: 500px) {
    .activeDialog {
        height: 80%;
        max-height: none !important;
        width: 95%;
    }

    .activeCondition {
        max-height: 300px;
    }
}

</style>