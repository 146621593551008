<template>
    <div class="phi-person-set-condition-appreciations">

       <div class="field_year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="model.year"   v-if="availableYears.length">
                <option v-for="year in availableYears" :value="year.id">{{ year.name }}</option>
            </select>
        </div>

       <div class="field_appreciation">
           
             <select v-model="model.appreciations" multiple="multiple">
                <option value="1">{{ $t("adj.Appreciation.promoted") }}</option>
                <option value="2">{{ $t("adj.Appreciation.mustRepeat") }}</option>
                <option value="3">{{ $t("adj.Appreciation.expelled") }}</option>
                <option value="4">{{ $t("adj.Appreciation.toDefine") }}</option>
                
            </select>
       </div>     
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-appreciations",
    props: ["api", "value"],
    data() {
        return {
            model: {
                year: null,
                appreciations: []
            },
            availableYears: [],
            appreciations: []

        }
    },

    watch: {
        "model": {
            deep: true,
            handler(newValue) {
                this.$emit("input", this.model);
            }
        }
    },

    methods: {
        loadAppreciation() {
         
            this.api
                .get("/v3/years")
                .then(response => {this.availableYears = response;

                    /* Set initial value */
                    if (this.value) {
                        this.model.year           = typeof this.value.year          != 'undefined' ? this.value.year    : null;
                        this.model.appreciations  = typeof this.value.appreciations  != 'undefined' ? this.value.appreciations  : null;
                        
                    }

                    if (!this.model.year) {
                        for (var i = 0; i < this.availableYears.length; i++) {
                            if (this.availableYears[i].current == "1") {
                                this.model.year = this.availableYears[i].id;
                                break;
                            }
                        }
                        if (!this.model.year) {
                            this.model.year = this.availableYears[0].id
                        }
                    }
                });

        }
    },


    mounted() {
        this.loadAppreciation();
    }
}
</script>