<template>
    <div class="phi-person-set-condition-and">
        <ul class="conditions">
            <li v-for="(condition, index) in value" class="condition">

                <div class="phi-media" @click="toggle(condition)">
                    <mu-icon :value="isExpanded(condition) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'" class="phi-media-figure" />
                    <p class="phi-media-body">{{ options.types[condition.type].title }}</p>
                    <mu-icon value="close" class="phi-media-right" @click.stop="removeConditionAt(index)" />
                </div>

                <phi-drawer :open="isExpanded(condition)">
                    <div class="widget">
                        <phi-person-set-condition
                            v-model="value[index]"
                            :api="api"
                            @input="$emit('input', value)"
                        ></phi-person-set-condition>
                    </div>
                </phi-drawer>
            </li>
        </ul>

        <div class="adder" v-if="options.types">
            <select @change="selectChanged">
                <option value="">-- {{ $t('action.addCondition') }} --</option>
                <option v-for="(condition, type) in options.types" :value="type" v-if="condition.title">{{ condition.title }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-and",

    /* https://vuejs.org/v2/guide/components.html#Circular-References-Between-Components */
    // beforeCreate: function () {
    //     this.$options.components.PhiPersonSetCondition = require('../Condition.vue');
    // },
    components: {
        PhiPersonSetCondition: () => import('../Condition.vue')
    },

	props: {
        value: {
            type: Array,
            required: true
        },

        options: {
            type: Object,
            required: true
        },

        api: {
            type: Object
        }
    },

    data() {
        return {
            expandedElements: []
        }
    },

    methods: {
        addCondition(condition) {
            this.value.push(condition);
            this.expand(condition);
            this.$emit('input', this.value);
        },

        removeConditionAt(index) {
            this.collapse(this.value[index]);
            this.value.splice(index, 1);
            this.$emit('input', this.value);
        },

        selectChanged(e) {
            this.addCondition({
                type: e.target.value,
                model: this.options.types[e.target.value].model
            });
            e.target.value = "";
        },

        isExpanded(condition) {
            return this.expandedElements.indexOf(condition) >= 0;
        },

        expand(condition) {
            if (this.isExpanded(condition)) {
                return;
            }
            this.expandedElements.push(condition);
        },

        collapse(condition) {
            this.expandedElements.splice(this.expandedElements.indexOf(condition), 1);
        },

        toggle(condition) {
            this.isExpanded(condition) ? this.collapse(condition) : this.expand(condition);
        }
    }
}
</script>

<style lang="scss" scoped>
.phi-person-set-condition-and {

    .conditions {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            .phi-media {
                cursor: pointer;
                padding: 14px 24px;
                align-items: center;
            }
        }

        .widget {
            padding: 14px 24px;
            border-bottom: 1px dashed #ccc;
        }
    }

    .adder {
        padding: 12px 24px;
    }

}
</style>