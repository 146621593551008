
<template>
    <div class="phi-person-set-condition-familyWithXChildren">

       <div class="field_numChildrenXFamily">
           
            <select v-model="model.numberChildren" multiple="multiple">
                 <option v-for="n in maxChildren" :value="n">{{n}}</option>
            </select>
       </div>     
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-familyWithXChildren",
    props: ["api", "value"],
    data() {
        return {
            model: {
           
                numberChildren: []
            },
           
            maxChildren:0,
            numberChildren: []

        }
    },

    watch: {
        "model": {
            deep: true,
            handler(newValue) {
                this.$emit("input", this.model);
            }
        }
    },

    methods: {
        load() {
         
            
                this.api
                .get("/v3/person/relation/maxChildrenXFamily")
                .then(response => this.maxChildren = response);

        }
    },


    mounted() {
        this.load();
    }
}
</script>

