<template>
    <div class="phi-person-set-condition-enrollment">
        <div class="field year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="model.year" v-if="availableYears.length">
                <option v-for="year in availableYears" :value="year.id">{{ year.name }}</option>
            </select>
        </div>

        <div class="field status">
            <label>{{ $t("noun.status") }}</label>
            <select v-model="model.status" multiple="multiple">
                <option value="2">{{ $t("adj.statusActive") }}</option>
                <option value="1">{{ $t("adj.statusPending") }}</option>
                <option value="8">{{ $t("adj.statusSuspended") }}</option>
                <option value="4">{{ $t("adj.statusWithdrawn") }}</option>
                <option value="32">{{ $t("adj.statusInscribed") }}</option>
                <option value="16">{{ $t("adj.statusGraduated") }}</option>
            </select>
        </div>

        <div class="field section">
            <label>{{ $t("noun.section") }}</label>
            <select v-if="availableCourses" v-model="model.section" multiple="multiple">
                <optgroup v-for="course in availableCourses" :label="course.name">
                    <option v-for="section in course.sections" :value="section.id">{{ section.name }}</option>
                </optgroup>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-enrollment",

    props: {
        value: {},
        api: {
            required: true
        }
    },

    data() {
        return {
            model: {
                year: null,
                status: [],
                section: []
            },
            availableYears: [],
            allSections: {}
        }
    },

    methods: {
        fetchSections(yearId) {
            if (typeof this.allSections[yearId] == "undefined") {
                this.api
                    .get("/v3/course/sections", {year: yearId})
                    .then(sections => this.$set(this.allSections, yearId, sections));
            }
        }
    },

    computed: {
        availableCourses() {
            if (typeof this.allSections[this.model.year] == "undefined") {
                return false;
            }

            let retval = [];

            for (var i = 0; i < this.allSections[this.model.year].length; i++) {
                var curSection = this.allSections[this.model.year][i];
                let foundCourseIndex = retval.findIndex(c => c.id == curSection.course.id);
                if (foundCourseIndex == -1) {
                    let nCourse = Object.assign({}, curSection.course, {sections:[]});
                    foundCourseIndex = retval.length;
                    retval.push(nCourse);
                }

                retval[foundCourseIndex].sections.push(curSection);
            }

            return retval;
        }
    },

    watch: {
        "model": {
            deep: true,
            handler(newValue) {
                this.$emit("input", this.model);
            }
        },

        "model.year": function(yearId, oldValue) {
            if (oldValue != null) {
                this.model.section = [];
            }
            this.fetchSections(yearId);
        }
    },

    beforeMount() {
        this.api
            .get("/v3/years")
            .then(years => {
                this.availableYears = years;

                /* Set initial value */
                if (this.value) {
                    this.model.year    = typeof this.value.year    != 'undefined' ? this.value.year    : null;
                    this.model.status  = typeof this.value.status  != 'undefined' ? this.value.status  : null;
                    this.model.section = typeof this.value.section != 'undefined' ? this.value.section : null;
                }

                if (!this.model.year) {
                    for (var i = 0; i < this.availableYears.length; i++) {
                        if (this.availableYears[i].current == "1") {
                            this.model.year = this.availableYears[i].id;
                            break;
                        }
                    }
                    if (!this.model.year) {
                        this.model.year = this.availableYears[0].id
                    }
                }
            });
    }

}
</script>

<style lang="scss">
.phi-person-set-condition-enrollment {
    display: flex;
    flex-wrap: wrap;

    .field {
        display: block;
        margin: 0 6px 12px 6px;

        label {
            display: block;
            margin-bottom: 2px;
            font-size: 12px;
            font-weight: bold;
        }

        &.status {
            select {
                height: 8em;
            }
        }

        &.section {
            select {
                min-height: 18em;
            }
        }
    }
}
</style>
