<template>
    <div class="phi-person-set-condition-transport">
        <select v-model="currentYear" @change="loadRoutesChangeYear(currentYear)">
            <option v-for="year in years" :value="year">{{year.name}}</option>
        </select>

        <select v-model="selectedRoutes" multiple="multiple" @change="$emit('input', selectedRoutes)">
            <optgroup v-for="shift in shifts" :label="shift.name">
                    <option v-for="route in shift.route" :value="route.id">{{ route.name }} </option>
            </optgroup>
        </select>
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-transport",
    props: ["api", "value"],
    data() {
        return {
            years: [],
            shifts: [],
            currentYear: null,
            selectedRoutes: this.value
        }
    },
    mounted() {
        this.loadYears();        
    },  
    methods: {
        loadYears() {
            return this.api
                .get(`/v3/academic/periods`)
                .then(response => {
                    this.years = response;
                    for (var i=0; i < response.length; i ++) {
                        if (this.years[i].current == "1") {
                            this.currentYear = this.years[i];
                            this.loadRoutes(this.years[i].id);
                            break;
                        }
                    }
                });
        },
        
        loadRoutes(yearId) {
            this.api
                .get(`/v3/transport/routes/year/${yearId}`)
                .then(response => this.shifts = response);
        },

        loadRoutesChangeYear(year) {
            console.log(year);
            this.loadRoutes(year.id);
        }
    }
}
</script>