<template>
	<div class="phi-person-set-condition-enrollment-modality">
		<div class="field year">
			<label>{{ $t("noun.academicYear") }}</label>
			<select v-model="model.year" v-if="availableYears.length">
				<option v-for="year in availableYears" :value="year.id">{{ year.name }}</option>
			</select>
		</div>

		 <div class="field modality">
            <label>{{ $t("noun.modality") }}</label>
			<select v-model="model.modalities" multiple="multiple">
				<option v-for="modality in modalitiesYear" :value="modality.id">{{ modality.name }}</option>
			</select>
		</div>
	</div>
</template>

<script>
export default {
	name: "phi-person-set-condition-enrollment-modality",

	props: {
		value: {},
		api: {
			required: true
		}
	},

	data() {
		return {
			model: {
				year: null,
				modalities: []
			},
			availableYears: [],
			modalitiesYear: {}
		}
	},

	mounted() {
    	this.fetchModalities();
    },

    beforeMount() {
		this.api
			.get("/v3/years")
			.then(years => {
				this.availableYears = years;

				/* Set initial value */
				if (this.value) {
					this.model.year       = typeof this.value.year != 'undefined' ? this.value.year : null;
					this.model.modalities = typeof this.value.modalities != 'undefined' ? this.value.modalities : null;
				}

				if (!this.model.year) {
					for (var i = 0; i < this.availableYears.length; i++) {
						if (this.availableYears[i].current == "1") {
							this.model.year = this.availableYears[i].id;
							break;
						}
					}
					if (!this.model.year) {
						this.model.year = this.availableYears[0].id
					}
				}
			});
	},

    methods: {
        fetchModalities(yearId) {
			this.api
				.get("/v3/course/section/enrollment/modalities", {year: yearId})
				.then(response => this.modalitiesYear = response);
		}
    },

	watch: {
        "model": {
            deep: true,
            handler(newValue) {
                this.$emit("input", this.model);
            }
        },

        "model.year": function(yearId, oldValue) {
            if (oldValue != null) {
                this.model.modalities = [];
            }
            this.fetchModalities(yearId);
        }
    }
}
</script>

<style lang="scss">
.phi-person-set-condition-enrollment-modality {
	display: flex;
	flex-wrap: wrap;

	.field {
		display: block;
		margin: 0 6px 12px 6px;

		label {
			display: block;
			margin-bottom: 2px;
			font-size: 12px;
			font-weight: bold;
		}
		
		&.modality {
			select {
				height: 8em;
			}
		}
	}
}
</style>
