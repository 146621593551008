<template>
    <div class="phi-person-set">
        <phi-drawer :open="!peopleShown" @input="updateValue">
            <div class="body">
                <phi-person-set-condition v-model="value" :api="api" @input="updateValue"></phi-person-set-condition>
            </div>
        </phi-drawer>

        <div class="summary" v-if="showTotal">
            <phi-drawer :open="peopleShown">
                <div class="list">
                    <div class="people">
                        <div
                            v-for="person in people"
                            :key="person.id"
                            class="person phi-media"
                        >
                            <div class="phi-media-figure phi-avatar">
                                <img :src="person.avatar" :alt="person.firstName" />
                            </div>
                            <h1 class="phi-media-body">{{ person.lastName }}, {{ person.firstName }}</h1>
                        </div>
                    </div>

                    <div class="phi-media close" @click="peopleShown = false">
                        <mu-icon value="close" class="phi-media-figure"></mu-icon>
                        <h1 class="phi-media-body">{{ $t('action.close') }}</h1>
                    </div>
                </div>
            </phi-drawer>

            <phi-drawer :open="!peopleShown">
                <div @click="peopleShown = true" class="label">
                    <span class="total" v-show="!isLoading">{{ count }} </span>
                    <i class="loader fa fa-circle-o-notch fa-spin" v-show="isLoading"></i>
                    <span class="text">{{ $t('noun.People') }}</span>
                </div>
            </phi-drawer>
        </div>
    </div>
</template>

<script>
import PhiPersonSetCondition from './Set/Condition.vue';

// Para funcionar standalone toca declarar los componentes que se asumian globales
import PhiDrawer from '@/components/Phi/Drawer.vue';


export default {
    name: "phi-person-set",

    components: {
        PhiPersonSetCondition,
        PhiDrawer
    },

	props: {
        value: {
            type: Object,
            required: true
        },

        api: {
            type: Object
        },

        showTotal: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isLoading: false,
            count: 0,
            people: null,
            peopleShown: false
        }
    },

    methods: {
        fetchPeople() {
            if (!this.showTotal) {
                return;
            }

            this.isLoading = true;
            return this.api
                .post("/sets/people/query", this.value)
                .then(people => {
                    this.people = people;
                    this.count  = people.length;
                })
                .catch()
                .then(() => this.isLoading = false);
        },

        updateValue(value) {
            this.fetchPeople();
            this.$emit("input", value);
        }
    },

    mounted() {
        this.fetchPeople();
    }
}
</script>

<style lang="scss" scoped>
.phi-person-set {
    .summary {
        padding: 8px 0;
        font-size: 0.9em;
        color: #444;

        .list {
            .people {
                max-height: 300px;
                overflow-y: auto;

                .person {
                    align-items: center;
                    h1 {
                        font-size: 1.3em;
                    }
                }
            }

            .close {
                align-items: center;
                cursor: pointer;
                &:hover {
                    background-color: rgba(0, 0, 0, .04);
                }

                h1 {
                    font-size: 1.2em;
                }
            }
        }

        .label {
            padding: 8px 12px;
            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, .04);
            }

            .total {
                font-weight: bold;
            }
        }

    }
}
</style>