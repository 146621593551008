<template>
  <component
    class="phi-person-set-condition"
    v-model="value.model"
    :is="value._component ? value._component : types[value.type].component"
    :options="options"
    :api="api"
    @input="$emit('input', value)"
  ></component>
</template>

<script>
import PhiPersonSetConditionAnd from "./Condition/And.vue";
import PhiPersonSetConditionOr from "./Condition/Or.vue";
import PhiPersonSetConditionSelect from "./Condition/Select.vue";
import PhiPersonSetConditionAttributes from "./Condition/Attributes.vue";
import PhiPersonSetConditionDebtor from "./Condition/Debtor.vue";
import PhiPersonSetConditionEnrollment from "./Condition/Enrollment.vue";
import PhiPersonSetConditionClass from "./Condition/Class.vue";
import PhiPersonSetConditionRoles from "./Condition/Roles.vue";
import PhiPersonSetConditionMentions from "./Condition/Mentions.vue";
import PhiPersonSetConditionEmployeeGroups from "./Condition/EmployeeGroups.vue";
import PhiPersonSetConditionDebit from "./Condition/Debit.vue";
import PhiPersonSetConditionDebitPrice from "./Condition/DebitPrice.vue";
import PhiPersonSetConditionTransport from "./Condition/Transport.vue";
import PhiPersonSetConditionAppreciations from "./Condition/Appreciations.vue";
import PhiPersonSetConditionFamilyWithXChildren from "./Condition/familyWithXChildren.vue";
import PhiPersonSetConditionPerson from "./Condition/Person.vue";
import PhiPersonSetConditionPeopleSetMemberships from "./Condition/PeopleSetMemberships.vue";
import PhiPersonSetConditionEnrollmentModality from "./Condition/EnrollmentModality.vue";

export default {
  name: "phi-person-set-condition",

  components: {
    PhiPersonSetConditionAnd,
    PhiPersonSetConditionOr,
    PhiPersonSetConditionSelect,
    PhiPersonSetConditionAttributes,
    PhiPersonSetConditionDebtor,
    PhiPersonSetConditionEnrollment,
    PhiPersonSetConditionClass,
    PhiPersonSetConditionRoles,
    PhiPersonSetConditionMentions,
    PhiPersonSetConditionDebit,
    PhiPersonSetConditionDebitPrice,
    PhiPersonSetConditionTransport,
    PhiPersonSetConditionEmployeeGroups,
    PhiPersonSetConditionAppreciations,
    PhiPersonSetConditionFamilyWithXChildren,
    PhiPersonSetConditionPerson,
    PhiPersonSetConditionPeopleSetMemberships,
    PhiPersonSetConditionEnrollmentModality
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    api: {
      type: Object
    }
  },

  beforeMount() {
    /* Look for defaults */
    if (!this.value.type) {
      throw "'type' is required";
    }
    if (typeof this.types[this.value.type] == "undefined") {
      throw "Unknown condition type '" + this.value.type + "'";
    }

    this.options = Object.assign({}, this.types[this.value.type].options);

    /* !!! special case */
    if (this.value.type == "and" || this.value.type == "or") {
      this.options = {
        types: this.types
      };
    }
  },

  data() {
    return {
      options: null,

      /* Definition of all available condition types */
      types: {
        and: {
          component: "phi-person-set-condition-and",
          model: []
        },

        or: {
          component: "phi-person-set-condition-or",
          model: []
        },

        type: {
          title: this.$t("person.condition.type"),
          component: "phi-person-set-condition-select",
          model: ["1"],
          options: {
            multiple: true,
            options: {
              "1": this.$t("noun.Students"),
              "2": this.$t("noun.Employees"),
              "3": this.$t("noun.Relatives")
            }
          }
        },

        attributes: {
          title: this.$t("person.condition.attributes"),
          component: "phi-person-set-condition-attributes",
          model: null
        },

        enrollment: {
          title: this.$t("person.condition.enrollment"),
          component: "phi-person-set-condition-enrollment",
          model: null
        },

        debtor: {
          title: this.$t("person.condition.debtor"),
          component: "phi-person-set-condition-debtor",
          model: true
        },

        v3Class: {
          title: this.$t("person.condition.v3Class"),
          component: "phi-person-set-condition-class",
          model: {
            periods: [],
            groups: [],
            grade: {
              countOperator: 3,
              count: 0,
              type: 0,
              operator: 3,
              value: 0
            }
          }
        },

        v3Role: {
          title: this.$t("person.condition.v3Role"),
          component: "phi-person-set-condition-roles",
          model: []
        },

        v3Transport: {
          title: this.$t("person.condition.v3Transport"),
          component: "phi-person-set-condition-transport",
          model: {
            routes: []
          }
        },

        V3Mentions: {
          title: this.$t("person.condition.V3Mentions"),
          component: "phi-person-set-condition-mentions",
          model: {
            periods: [], //en esperar...
            mentions: [],
            year: null
          }
        },

        v3EmployeeGroups: {
          title: this.$t("person.condition.v3EmployeeGroups"),
          component: "phi-person-set-condition-employee-groups",
          model: {
            group: [],
            status: [],
            year: null
          }
        },

        V3PeopleSetMemberships: {
          title: this.$t("person.condition.V3PeopleSetMemberships"),
          component: "phi-person-set-condition-people-set-memberships",
          model: {
            pset: [],
            year: null
          }
        },

        v3Debit: {
          title: this.$t("person.condition.v3Debit"),
          component: "phi-person-set-condition-debit",
          model: {
            periods: [],
            prices: [],
            operator: ">",
            balance: 0
          }
        },

        v3DebitPrice: {
          title: this.$t("person.condition.v3DebitPrice"),
          component: "phi-person-set-condition-debit-price",
          model: []
        },

        v3Appreciation: {
          title: this.$t("person.condition.v3Appreciation"),
          component: "phi-person-set-condition-appreciations",
          model: null
        },

        v3FamilyWithXChildren: {
          title: this.$t("person.condition.v3FamilyWithXChildren"),
          component: "phi-person-set-condition-familyWithXChildren",
          model: null
        },

        v3ChildrenWithEnrollment: {
          title: this.$t("person.condition.v3ChildrenWithEnrollment"),
          component: "phi-person-set-condition-enrollment",
          model: null
        },

        person: {
          component: "phi-person-set-condition-person",
          model: null
        },

        V3EnrollmentModality: {
          title: this.$t("person.condition.V3EnrollmentModality"),
          component: "phi-person-set-condition-enrollment-modality",
          model: {
            modalities: [],
            year: null
          }
        }
      }
    };
  }
};
</script>
