<template>
  <div>
    <div
      class="ui-dialog-trigger"
      @mousedown="onTriggerMousedown()"
      @click="onTriggerClick()"
    >
      <slot
        name="trigger"
        :open="doOpen"
      ></slot>
    </div>

    <div :class="['ui-dialog-widget', containerClass, $el ? $el.className : null]">
      <transition name="trDialog">
        <div
          v-if="innerOpen"
          class="ui-dialog"
        >
          <div
            class="ui-dialog-scrim"
            @click="onClickScrim()"
          ></div>

          <div class="ui-dialog-container ui-card ui-z">
            <slot name="header">
              <h1
                class="ui-dialog-title"
                v-show="title"
              >{{ title }}</h1>
            </slot>

            <div class="ui-dialog-content">
              <slot
                name="contents"
                :close="doClose"
              >
                <!-- final fallback, use default slot as contents -->
                <slot name="default"></slot>
              </slot>
            </div>

            <div
              class="ui-dialog-actions"
              v-if="$slots.footer || $scopedSlots.footer || !!allActions.length"
            >
              <slot
                name="footer"
                :close="doClose"
              >
                <template v-if="allActions">
                  <button
                    v-for="(action,i) in allActions"
                    :key="i"
                    type="button"
                    class="ui-button"
                    :class="action.className"
                    @click="onClickAction(action, $event)"
                  >{{ action.label }}</button>

                  <slot name="actions" :close="doClose"></slot>
                </template>
              </slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDialog',

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    actions: {
      type: Array,
      required: false,
      default: () => [],
    },

    open: {
      type: Boolean,
      required: false,
      default: false,
    },

    closeScrim: {
      type: Boolean,
      required: false,
      default: true,
    },

    triggerEvent: {
      type: String,
      required: false,
      default: 'mousedown', // puede ser 'mousedown', 'click', o null para no disparar ningun evento en el trigger
    },

    containerClass: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerOpen: false,
      widgetEl: null,
    };
  },

  mounted() {
    this.widgetEl = this.$el.querySelector('.ui-dialog-widget');
    document.body.appendChild(this.widgetEl);

    if (this.open) {
      this.$nextTick(() => (this.innerOpen = true));
    }
  },

  watch: {
    open(newValue) {
      this.innerOpen = newValue;
      this.$emit(this.innerOpen ? 'open' : 'close');
    },
  },

  computed: {
    allActions() {
      let retval = this.actions.concat([]);

      if (this.$listeners.accept) {
        retval.push({
          label: 'Aceptar',
          action: this.$listeners.accept,
          className: '--main',
          autoclose: true,
        });
      }

      if (this.$listeners.cancel) {
        retval.push({
          label: 'Cancelar',
          action: this.$listeners.cancel,
          className: '--cancel',
          autoclose: true,
        });
      }

      return retval;
    },
  },

  methods: {
    onTriggerMousedown() {
      if (this.triggerEvent != 'mousedown') {
        return;
      }

      return this.doOpen();
    },

    onTriggerClick() {
      if (this.triggerEvent != 'click') {
        return;
      }

      return this.doOpen();
    },

    doOpen() {
      this.innerOpen = true;
      this.$emit('update:open', true);
      this.$emit('open');
    },

    onClickScrim() {
      if (this.closeScrim) {
        this.doClose();
      }
      this.$emit('cancel');
    },

    doClose() {
      this.innerOpen = false;
      this.$emit('update:open', false);
      this.$emit('close');
    },

    onClickAction(action, $event) {
      let result = action.action($event);
      if (action.autoclose) {
        this.doClose();
      }
      return result;
    },
  },

  destroyed() {
    try {
      document.body.removeChild(this.widgetEl);
    } catch (e) {
      // El elemento ya estaba desmontado (?)  pasa en casos cuando se usa <ui-dialog v-if="XXX">
    }
  },
};
</script>

<style lang="scss">
.ui-dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;

  // Alinear al centro
  display: flex;
  align-items: center;
  justify-content: center;

  .ui-dialog-container {
    width: 90vw;
    max-width: 1250px;
    max-height: 80%;
    overflow: hidden;

    // ubicar a una distancia fija de la parte superior de la ventana.
    // De esta forma es mas usable cuando hay contenido dinamico cambiando de alto en el interior del dialogo
    // (por ejemplo Tabs que hacen variar la altura del dialogo.  Al centrarlo verticalmente los cambios de altura dan la ilusion de "saltos")
    // sobre todo cuando hay tabs en la parte superior del dialogo
    position: absolute;
    top: 10%;

    display: flex;
    flex-direction: column;

    .ui-dialog-title {
      font-size: 1.2rem;
      padding: var(--ui-padding);
      font-weight: bold;
    }

    .ui-dialog-content {
      flex: 1;
      overflow: auto;
      padding: var(--ui-padding);
      // max-height: 80vh;
      max-height: 800px;
    }
  }

  .ui-dialog-scrim {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
  }

  .ui-dialog-actions {
    padding: var(--ui-padding);
    background-color: #f3f3f3;

    button {
      margin-right: 7px;
    }
  }
}

.trDialog-enter-active,
.trDialog-leave-active {
  transition: .05s ease-in-out;
}

.trDialog-enter,
.trDialog-leave-to {
  opacity: 0;
}

.trDialog-enter-to,
.trDialog-leave {
  opacity: 1;
}

@media (max-width: 800px) {
  .ui-dialog {
    .ui-dialog-container {
      top: 10px;
      bottom: 10px;
      left: 10px;
      right: 10px;
      width: auto;
    }
  }
}
</style>