<template>
    <div class="debit-price-descriptor">

         <div class="field year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="currentYear" @change="triggerCurrentYearChange(true)">
                <option v-for="year in years" :value="year">{{ year.name }}</option>
            </select>
         </div>

         <div class="field period">
            <label>{{ $t("noun.periods") }}</label>
            <select multiple v-model="model.periods" v-if="!!currentYear">
                <option v-for="period in currentYear.periods" :value="period.id">{{ period.name }}</option>
            </select>
         </div>

        <div class="field price">
            <multiselect
                v-model="selectedPrices"
                v-if="flatPrices.length > 0"
                :options="flatPrices"
                :multiple="true"
                :placeholder="$t('pick prices')"
                :select-label="$t('action.select')"
                :selected-label="$t('adj.selected')"
                :deselect-label="$t('action.deselect')"
                group-label="name"
                group-values="prices"
                track-by="id"
                label="name"
            >
                <span slot="noResult">{{ $t("no matches") }}</span>
            </multiselect>
        </div>

        <div class="field balance">
            <label>{{ $t("noun.Balance") }}</label>
            <select v-model="model.operator">
                <option v-for="operator in operators" :value="operator">{{ operator }}</option>
            </select>

            <input v-model="model.balance" type="number" min="0" step="1" />
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'debit-price-descriptor',
    components: {
        Multiselect
    },
    props: ['api', 'value'],
    data() {
        return {
            model: this.value,
            years: [],
            selectedPrices: [],
            operators: ["<", "<=", "=", ">", ">="],
            currentYear: null,
            loadingModel: false,
            quering: false,
            flatPrices: []
        }
    },

    methods: {
        loadYears() {
            return this.api
                .get(`/v3/debit/periods`)
                .then(response => this.years = response);
        },

        triggerCurrentYearChange(doReset) {
            if (doReset && this.loadingModel === false) {
                this.model.prices  = [];
                this.model.periods = [];
                this.selectedPrices = [];
            }

            if(!this.quering){
                this.quering = true;
                this.api
                    .get(`/v3/years/${this.currentYear.id}/concept/prices`)
                    .then(response => {
                        this.flatPrices = [];

                        response.forEach(group => {
                            if(group.concepts.length > 0){
                                let groupObj = {
                                    name: group.name,
                                    prices: []
                                };

                                group.concepts.forEach(concept => {
                                    concept.prices.forEach(price => {
                                        let priceObj = {
                                            id: price.id,
                                            name: concept.name.toLowerCase().trim() == price.name.toLowerCase().trim() ? price.name : concept.name.toLowerCase().trim() == groupObj.name.toLowerCase().trim() ? price.name : concept.name + " / " + price.name
                                        };
                                        groupObj.prices.push(priceObj);

                                        if(this.model.prices.includes(priceObj.id)){
                                            this.selectedPrices.push(priceObj);
                                        }
                                    });
                                });

                                this.flatPrices.push(groupObj);
                            }
                        });

                        this.loadingModel = false;
                        this.quering = false;
                    });
            }
        },

        findYearWithPeriod(periodId) {
            for (let y = 0; y < this.years.length; y++) {
                let year = this.years[y];

                for (let i = 0; i < year.periods.length; i++) {
                    if (year.periods[i].id == periodId) {
                        return year;
                    }
                }
            }

            return null;
        }
    },

    mounted() {
        this.loadYears()
            .then(() => {
                if (this.model.periods.length || this.model.prices.length) {
                    this.loadingModel = true;
                    this.currentYear = this.findYearWithPeriod(this.model.periods[0]);
                } else {
                    for (var i = 0; i < this.years.length; i++) {
                        if (this.years[i].current == "1") {
                            this.currentYear = this.years[i];
                            break;
                        }
                    }
                }

                this.triggerCurrentYearChange(false);
            });
    },

    watch: {
        model: {
            deep: true,
            handler() {
                this.$emit('input', this.model);
            }
        },

        selectedPrices: {
            deep: true,
            handler() {
               this.model.prices = this.selectedPrices.map(selectedPrice => selectedPrice.id);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('../../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css');

.debit-price-descriptor{
    /* lets test css grid :P */
    display: grid;
    grid-template-columns: 40% 5% 55%;
    grid-template-rows: auto auto auto;

    .field{
        margin-bottom: 10px;

        label{
            vertical-align: top;
        }
    }

    .field.year{
        grid-column: 1;
        grid-row: 1;
    }

    .field.period{
        grid-column: 3;
        grid-row: 1;
    }

    .field.price{
        grid-column: 1 / 4;
        grid-row: 2;
    }

    .field.balance{
        grid-column: 1 / 4;
        grid-row: 3;
    }
}
</style>