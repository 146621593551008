<template>
    <div class="component-flatpickr">
        <input type="text" :class="inputClass" :placeholder="placeholder" data-input />
        <a class="icon" data-toggle v-if="icon">
            <mu-icon :value="icon" />
        </a>
    </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'flatpickr';

export default {
    name: "faltpickr",

    props: {
        config: {
            type: Object,
            default() {
                return {
                    enableTime: true,
                    dateFormat: 'M j, Y h:i K'
                };
            }
        },

        placeholder: {
            type: String
        },

        inputClass: {
            type: String
        },

        name: {
            type: String
        },

        value: {
            type: Date
        },

        icon: {
            type: String
        }
    },

    mounted() {
        this.instance = Flatpickr(this.$el, Object.assign(this.config, {
            onChange: dates => {
                this.$emit("input", dates.length ? dates[0] : null);
            },
            wrap: true
        }));

        this.instance.setDate(this.value ? new Date(this.value) : null);
    },

    data() {
        return {
            instance: null
        };
    },

    watch: {
        value() {
            this.instance.setDate(this.value);
            
            if (this.config.minDate=="today") {
                const today = new Date().setHours(0, 0, 0, 0);
                const selectedDate = new Date(this.value).setHours(0, 0, 0, 0);
                if(today === selectedDate){
                    this.instance.set("minTime", new Date(new Date().getTime() + 60 * 60 * 1000).toLocaleTimeString(undefined, { hour12: false }))
                }
                else{
                    this.instance.set("minTime", null )
                }
                // console.log(today,selectedDate)
            }
            // console.log(this.instance)
        },

        'config.dateFormat': function(newValue) {
            this.instance.set('dateFormat', newValue);
            this.instance.setDate(this.value);
        },

        // This just wouldn't work.  Flatpickr doesnt update the setting :(
        // 'config.enableTime': function(newValue) {
        //     this.instance.set('enableTime', newValue);
        //     this.instance.redraw();
        // }
    }
}
</script>

<style lang="scss">
.component-flatpickr {
    cursor: pointer;

    // display: flex;
    // align-items: center;

    input {
        border: 0;
        background: transparent;
        color: inherit;
        font-size: inherit;
        display: inline-block;
    }

    .icon {
        display: inline-block;
        margin-left: .5em;
    }
}
</style>