<template>
    <div class="phi-person-set-condition-debit">
        <div class="field year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="currentYear" @change="triggerCurrentYearChange(true)">
                <option v-for="year in years" :value="year">{{ year.name }}</option>
            </select>
         </div>

        <div class="field period">
            <label>{{ $t("noun.periods") }}</label>
            <select multiple v-model="model.periods" v-if="!!currentYear">
                <option v-for="period in currentYear.periods" :value="period.id">{{ period.name }}</option>
            </select>
        </div>

        <div class="field value">
            <label>{{ $t("noun.value") }}</label>
            <select v-model="model.operator">
                <option v-for="operator in operators" :value="operator">{{ operator }}</option>
            </select>

            <input v-model="model.balance" type="number" min="0" step="1" />
        </div>

        <div class="field prices">
            <label>{{ $t("noun.prices") }}</label>

            <div class="toolbar phi-media" v-if="fuse !== null && groups.length > 0">
                <div class="phi-media-body">
                    <input class="search-field" type="text" v-model="query" :placeholder="$t('search')" />
                </div>
                <button type="button" class="phi-media-right phi-button" v-if="query.length > 0" @click="query = ''">
                    <i class="fa fa-times"></i>
                </button>
                <button type="button" class="phi-media-right phi-button" v-else>
                    <i class="fa fa-search"></i>
                </button>
            </div>

            <section class="groups">
                <div class="group" v-for="group in filteredGroups">
                    <input type="checkbox" :id="'group_'+group.id" @change="toggleGroup(group)" :checked="isGroupSelected(group)" />
                    <label :for="'group_'+group.id">{{ group.name }}</label>

                    <section class="concepts">
                        <div class="concept" v-for="concept in group.concepts">
                            <input type="checkbox" :id="'concept_'+concept.id" @change="toggleConcept(concept)" :checked="isConceptSelected(concept)" />
                            <label :for="'concept_'+concept.id">{{ concept.name }}</label>

                            <section class="prices">
                                <div class="price" v-for="price in concept.prices">
                                    <input type="checkbox" :id="'price_'+price.id" :checked="isPriceSelected(price)" @change="togglePrice(price)" />
                                    <label :for="'price_'+price.id">{{ price.name }} ({{price.value}})</label>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Fuse from 'fuse.js';

export default {
    name: 'phi-person-set-condition-debit',
    props: ['api', 'value'],
    data() {
        return {
            years: [],
            currentYear: null,
            groups: [],
            operators: ["<", "<=", "=", ">", ">="],
            model: this.value,
            loadingModel: false,

            query: '',
            fuse: null,
            searchOptions: {
                shouldSort: true,
                threshold: 0.2,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 2,
                keys: [
                    "name",
                    "concepts.name",
                    "concepts.prices.name"
                ]
            }
        }
    },

    computed: {
        filteredGroups() {
            let query = this.query.trim();

            let finalArray = [];

            if (query.length > 0) {
                finalArray = this.fuse.search(query);
            } else {
                finalArray = this.groups;
            }

            return finalArray;
        }
    },


    methods: {
        loadYears() {
            return this.api
                .get(`/v3/debit/periods`)
                .then(response => this.years = response);
        },

        triggerCurrentYearChange(doReset) {
            if (doReset && this.loadingModel === false) {
                this.model.prices  = [];
                this.model.periods = [];
            }

            this.fuse = null;

            this.api
                .get(`/v3/years/${this.currentYear.id}/concept/prices`)
                .then(response => {
                    this.groups = response;

                    this.fuse = new Fuse(this.groups, this.searchOptions);
                    this.loadingModel = false;
                });

        },

        findYearWithPeriod(periodId) {
            for (let y = 0; y < this.years.length; y++) {
                let year = this.years[y];

                for (let i = 0; i < year.periods.length; i++) {
                    if (year.periods[i].id == periodId) {
                        return year;
                    }
                }
            }

            return null;
        },

        isPriceSelected(price) {
            return this.model.prices.indexOf(price.id) >= 0;
        },

        selectprice(price) {
            if (this.isPriceSelected(price)) {
                return;
            }
            this.model.prices.push(price.id);
        },

        deselectprice(price) {
            this.model.prices.splice(this.model.prices.indexOf(price.id), 1);
        },

        togglePrice(price) {
            this.isPriceSelected(price) ? this.deselectprice(price) : this.selectprice(price);
        },

        isConceptSelected(concept) {
            return concept.prices.some(this.isPriceSelected);
        },

        selectconcept(concept) {
            concept.prices.forEach(this.selectprice);
        },

        deselectconcept(concept) {
            concept.prices.forEach(this.deselectprice);
        },

        toggleConcept(concept) {
            this.isConceptSelected(concept) ? this.deselectconcept(concept) : this.selectconcept(concept);
        },


        isGroupSelected(group) {
            return group.concepts.some(this.isConceptSelected);
        },

        selectgroup(group) {
            group.concepts.forEach(this.selectconcept);
        },

        deselectgroup(group) {
            group.concepts.forEach(this.deselectconcept);
        },

        toggleGroup(group) {
            this.isGroupSelected(group) ? this.deselectgroup(group) : this.selectgroup(group);
        }

    },

    mounted() {

        this.loadYears()
            .then(() => {
                if (this.model.periods.length) {
                    this.loadingModel = true;
                    this.currentYear = this.findYearWithPeriod(this.model.periods[0]);
                } else {
                    for (var i = 0; i < this.years.length; i++) {
                        if (this.years[i].current == "1") {
                            this.currentYear = this.years[i];
                            break;
                        }
                    }
                }

                this.triggerCurrentYearChange(false);
            });
    },

    watch: {
        model: {
            deep: true,
            handler() {
                this.$emit('input', this.model);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    &>div {
        &>h2 {
            font-size: 1em;
        }
    }

    section {
        padding-left: 16px;
    }
}

.phi-person-set-condition-class {
    display: flex;
    flex-wrap: wrap;

    .field {
        display: block;
        margin: 0 6px 12px 6px;

        >label {
            display: block;
            margin-bottom: 2px;
            font-size: 12px;
            font-weight: bold;
        }

        &.status {
            select {
                height: 8em;
            }
        }

        &.section {
            select {
                min-height: 18em;
            }
        }
    }
}

input.search-field{
    width: 100%;
    content: '\1F50D';
    background: transparent;
    font-size: 1em;
    border: 0;
    border-bottom: 1px solid #999;
}

.toolbar{
    box-sizing: border-box;
    height: 40px;
    padding: 0 16px 10px 16px;
    display: flex;
    align-items: center;

    button{
        background: transparent;
        border: 0;
        padding: 0;
        font-size: 1.2em;
        width: 32px;
        height: 32px;
        color: inherit;
        box-shadow: none;
    }
}
</style>