<template>
    <phi-page color="#f2f2f2" :loading="app.api.isLoading">
        <div slot="toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
            <h1>{{ $t(type.singular) }}</h1>
        </div>

        <section class="editor">
            <phi-type-editor v-if="type" v-model="type" @input="saveType"></phi-type-editor>
            <mu-icon value="delete" @click.native="deleteTypeDialog = true" v-if="$route.meta.allowed && isTypeNotDelete"></mu-icon>
        </section>

        <section class="flags" v-if="type">
            <!--
            The @input event fires ALWAYS on load, so we can't use it
            The @change event fires BEFORE the model is updated, so the new flags value is assigned on the event
            -->
            <mu-checkbox
                v-model="type.flags"
                :label="type.flags == 1 ? $t('type.Invisible') : $t('type.Visible')"
                uncheck-icon="visibility"
                checked-icon="visibility_off"
                @change="$set(type, 'flags', arguments[0]); saveType()"
            ></mu-checkbox>
        </section>

        <section class="rules">
            <div class="rule phi-card" v-for="(rule, i) in rules" :key="rule.id">
                <mu-icon class="delete" value="delete" @click="deleteRule(rule)"></mu-icon>
                <phi-post-type-rule
                    :api="app.api"
                    v-model="rules[i]"
                    @input="updateRule(rule)"
                ></phi-post-type-rule>
            </div>


            <div class="phi-card adder">
                <phi-drawer :open="creatingRule">
                    <phi-post-type-rule
                        :api="app.api"
                        v-model="newRule"
                    ></phi-post-type-rule>

                    <div class="actions">
                        <div class="phi-media new" @click="addNewRule(newRule)">
                            <mu-icon class="phi-media-figure" value="check"></mu-icon>
                            <span class="phi-media-body">{{ $t('OK') }}</span>
                        </div>
                        <div class="phi-media new" @click="creatingRule = false">
                            <mu-icon class="phi-media-figure" value="close"></mu-icon>
                            <span class="phi-media-body">{{ $t('action.cancel') }}</span>
                        </div>
                    </div>

                </phi-drawer>

                <phi-drawer :open="!creatingRule">
                    <div class="phi-media new" @click="creatingRule = true">
                        <mu-icon class="phi-media-figure" value="add"></mu-icon>
                        <span class="phi-media-body">{{ $t('action.addNewRule') }}</span>
                    </div>
                </phi-drawer>
            </div>
        </section>

        <!-- Modal para confirmar eliminar el tipo -->
        <ui-dialog :open.sync="deleteTypeDialog" title="¿Seguro desea eliminar este tipo de comunicado?">
            <p>Al eliminar el tipo <b>{{ $t(type.singular) }}</b> también eliminará los mensajes relacionados y se perderá trazabilidad.</p>
            <p class="delete-notice">* Para eliminar este tipo de comunicado debe ingresar el texto entre comillas (sin las comillas) "<b>{{ $t(type.singular) }}</b>" a continuación.</p>

            <p class="required-delete-name" v-if="deleteTypeAlert">El nombre no coincide con el tipo de comunicado</p>
            <ui-input type="text" v-model="deleteTypeName" placeholder="Confirmar nombre singular"></ui-input>

            <template #footer>
                <button type="button" class="ui-button --main" @click="deleteType(type)">Aceptar</button>
                <button type="button" class="ui-button" @click="deleteTypeDialog = false">Cancelar</button>
            </template>
        </ui-dialog>

    </phi-page>
</template>

<script>
import app from '../../../store/app.js';
import PhiTypeEditor from '../../../components/Phi/Type/Editor.vue';
import PhiPostTypeRule from '../../../components/Phi/Post/Type/Rule.vue';
import UiDialog from '@/modules/ui/components/UiDialog/UiDialog.vue';
import UiInput from '@/modules/ui/components/UiInput/UiInput.vue';

export default {
    name: 'post-type-edit',

    components: {
        PhiTypeEditor,
        PhiPostTypeRule,
        UiDialog,
        UiInput,
    },

    data() {
        return {
            app,
            deleteTypeDialog: false,
            deleteTypeName: '',
            deleteTypeAlert: false,
            typeId: encodeURIComponent(this.$route.params.type),
            type: {},
            rules: [],

            creatingRule: false,
            newRule: {
                audience: {
                    "type": "or",
                    "model": []
                },

                settings: {
                    redact: 3,
                    attach: [],
                    calendar: false,
                    bcc: {
                        default: false,
                        editable: true
                    },
                    allowReplies: {
                        default: true,
                        editable: false
                    },
                    highlight: {
                        default: false,
                        editable: true
                    },
                    notify: {
                        default: true,
                        editable: false
                    }
                }
            }
        };
    },

    computed: {
        isTypeNotDelete() {
            if (this.$route.meta.typesFixed.includes(this.typeId.toLowerCase())) {
                return false;
            }
            return true;
        }
    },

    methods: {
        load() {
            this.app.api
                .get(`/types/bulletin/${this.typeId}`)
                .then(response => this.type = response);

            this.app.api
                .get(`/post/types/${this.typeId}/rules`, {_nocache: Math.random()})
                .then(response => this.rules = response);
        },

        saveType() {
            this.app.api
                .put(`/types/bulletin/${this.typeId}`, this.type)
                .then(updatedType => {
                    this.typeSingular = updatedType.singular;
                    this.typeId       = encodeURIComponent(updatedType.singular);
                });
        },

        addNewRule(rule) {
            this.creatingRule = false
            this.app.api
                .post(`/post/types/${this.typeId}/rules`, rule)
                .then(createdRule => this.rules.push(createdRule));
        },

        updateRule(rule) {
            this.app.api
                .put(`/post/types/${this.typeId}/rules/${rule.id}`, rule);
        },

        deleteRule(rule) {
            if (!confirm( this.$t('question.deleteThisRule') )) {
                return;
            }

            this.app.api
                .delete(`/post/types/${this.typeId}/rules/${rule.id}`)
                .then(() => this.rules.splice(this.rules.indexOf(rule), 1));
        },

        deleteType(type) {
            if (!this.deleteTypeName || this.deleteTypeName != type.singular) {
                this.deleteTypeAlert = true;
                return;
            }

            if (!confirm(`¿Desea eliminar el tipo ${type.singular}?`)) {
                this.deleteTypeName = '';
                this.deleteTypeDialog = false;
                this.deleteTypeAlert = false;
                return;
            }
            
            let encodeName = encodeURIComponent(this.deleteTypeName);
            this.app.api
                .delete(`/types/${encodeName}/${encodeName}`)
                .then((item) => {
                    this.deleteTypeName = '';
                    this.deleteTypeDialog = false;
                    this.deleteTypeAlert = false;
                    this.$router.go(-1);
                });
        }
    },

    mounted() {
        this.load();
    }
}
</script>

<style lang="scss" scoped>
section {
    margin-bottom: 24px;

    &.flags {
        padding: 0 16px;
    }
}

.editor {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .phi-type-editor {
        width: 100px;
        flex-grow: 1;
    }

    & > .mu-icon {
        color: red;
        cursor: pointer;
    }
}

.required-delete-name {
    color: red;
}

.delete-notice {
    padding: 15px 5px;
    background: #e7e7e7;
    border-radius: 4px;
}

.rules {

    .adder {
        .actions {
            display: flex;

            & > div {
                flex: 1;
            }
        }
    }

    .new {
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 0, 0, 0.01);
        }
    }

    .rule {
        position: relative;
        padding-top: 24px;
        margin-bottom: 18px;

        .delete {
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;

            cursor: pointer;
            color: #900;
        }
    }
}

</style>