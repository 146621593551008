<template>
    <ul class="phi-person-set-condition-attributes">
        <li v-for="property in properties">
            <label>{{ $t(property.title) }}</label>

            <div v-if="property.type == 'string'">
                <select v-model="property.operator">
                    <option :value="null"> --- </option>
                    <option value="eq">{{ $t('condition.attributes.equalTo') }}</option>
                    <option value="start">{{ $t('condition.attributes.startsWith') }}</option>
                    <option value="contains">{{ $t('condition.attributes.contains') }}</option>
                </select>
                <input type="text" v-model="property.value" v-show="!!property.operator" />
            </div>

            <div v-if="property.type == 'list'">
                <select v-model="property.value">
                    <option :value="null"> --- </option>
                    <option v-for="(item,key) in property.options" :value="item">{{ key }}</option>
                </select>
            </div>

            <div v-if="property.type == 'date'">
                <select v-model="property.operator">
                    <option :value="null"> --- </option>
                    <option value="eqD">{{ $t('condition.attributes.equalTo') }}</option>
                    <option value="lt">{{ $t('condition.attributes.lessThan') }}</option>
                    <option value="gt">{{ $t('condition.attributes.greaterThan') }}</option>
                    <option value="btw">{{ $t('condition.attributes.between') }}</option>
                </select>
                <input type="text" v-model="property.fdate" v-show="!!property.operator" />
                <span v-show="!!property.operator && property.operator == 'btw'"> y <input type="text" v-model="property.ldate"/></span>
                <!-- <flatpickr v-model="property.fdate" class="start-date" v-show="!!property.operator" placeholder="Fecha inicial"></flatpickr>
                <span v-show="!!property.operator && property.operator == 'btw'"> y <flatpickr v-model="property.ldate" class="end-date" placeholder="Fecha final"></flatpickr></span>
                -->
            </div>
        </li>
    </ul>
</template>

<script>
import Flatpickr from '../../../../Flatpickr.vue';

export default {
    name: "phi-person-set-condition-attributes",
    components: {Flatpickr},
    props: {
        value: {}
    },

    data() {
        return {
            model: this.value,

            properties: [
                {
                    name: "firstName",
                    title: "condition.attributes.firstName",
                    type: "string",
                    operator: null,
                    value: null
                },
                {
                    name: "lastName",
                    title: "condition.attributes.lastName",
                    type: "string",
                    operator: null,
                    value: null
                },
                {
                    name: "gender",
                    title: "condition.attributes.gender",
                    type: "list",
                    options: {M:"1", F:"0"},
                    operator: "eq",
                    value: null
                },
                {
                    name: "document",
                    title: "condition.attributes.document",
                    type: "string",
                    operator: null,
                    value: null
                },
                // {
                //     name: "birthDay",
                //     title: "birthdate",
                //     type: "date",
                //     operator: null,
                //     fdate: null,
                //     ldate: null
                // },
            ],

            timeout: null
        };
    },

    computed: {
         fdate: {
            get(){
                return 999999999999;
            }
        }
    },

    methods: {
        toJSQL() {
            var retval = {};
            var hasData = false;

            this.properties.forEach(property => {
                if (!property.operator) {
                    return;
                }

                if (property.type == 'date' && (property.operator == 'gt' || property.operator == 'lt' || property.operator == 'eqD') && !property.fdate) {
                    //console.log('WTF');
                    return null;
                }

                if (property.type != 'date' && !property.value){
                    //console.log()
                    return null;
                }

                hasData = true;

                switch (property.operator) {

                    case 'eq':
                        retval[property.name] = property.value;
                    break;

                    case 'start':
                        retval[property.name] = {'&like': property.value+'%'};
                    break;

                    case 'contains':
                        retval[property.name] = {'&like': '%'+property.value+'%'};
                    break;

                    case 'gt':
                        retval[property.name] = {'&gt': property.fdate};
                    break;

                    case 'lt':
                        retval[property.name] = {'&lt': property.fdate};
                    break;

                    case 'eqD':
                        retval[property.name] = property.fdate;
                    break;

                    case 'btw':
                        retval[property.name] =  property.fdate < property.ldate ? {'&between': [property.fdate, property.ldate]} : {'&between': [property.ldate, property.fdate]};
                    break;

                }
            });

            return hasData ? retval : null;
        },

        getFromModel(){
            for (let key in this.model){
                for (let property in this.properties){
                    if (this.properties[property].name == key) {
                        if (this.model[key] && typeof this.model[key] == 'object') {
                            for (let name in this.model[key]) {
                                switch (name) {
                                    case '&like':
                                        let oValue = this.model[key][name];
                                        if ( oValue.charAt(0) == '%' && oValue.charAt(oValue.length - 1) == '%' ) {
                                            this.properties[property].operator = 'contains';
                                        }else if ( oValue.charAt(oValue.length - 1) == '%' ){
                                            this.properties[property].operator = 'start';
                                        } //... maybe an 'ends' option .??
                                        this.properties[property].value = oValue.replace(new RegExp("%", 'g'), "");
                                    break;

                                    case '&gt':
                                        this.properties[property].operator = 'gt';
                                        this.properties[property].fdate = this.model[key][name];
                                    break;

                                    case '&lt':
                                        this.properties[property].operator = 'lt';
                                        this.properties[property].fdate = this.model[key][name];
                                    break;

                                    case '&between':
                                        this.properties[property].operator = 'btw';
                                        this.properties[property].fdate = this.model[key][name][0];
                                        this.properties[property].ldate = this.model[key][name][1];
                                    break;
                                }
                            }
                        }else {
                            this.properties[property].operator = 'eq';
                            this.properties[property].value = this.model[key];
                        }
                    }
                }
            }
        }
    },

    mounted() {
        if (this.model) {
            this.getFromModel();
        }
    },


    watch: {
        properties: {
            deep: true,
            handler() {
                this.model = this.toJSQL();
            }
        },

        model: {
            deep: true,
            handler() {
                if(this.model) {
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        console.log('delayed!');
                        this.$emit('input', this.model);
                    }, 500)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.phi-person-set-condition-attributes {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: flex;

        label {
            width: 120px;
        }
    }
}
</style>
